.google-location-div .css-yk16xz-control {
  height: 67px;
  background-color: #f0f0f0 !important;
  border-radius: 0px !important;
  border: 1px solid #AFAFAF !important;
  padding-left: 36px;
}
.google-location-div .css-1wa3eu0-placeholder {
  /* padding-left: 36px; */
  font-size: 14px;
}
.google-location-div .css-1pahdxg-control:hover {
  height: 67px;
  background-color: #f0f0f0 !important;
  border-radius: 0px !important;
  border-color: #AFAFAF !important ;
}
.google-location-div .css-1pahdxg-control {
  height: 67px;
  background-color: #f0f0f0 !important;
  border-radius: 0px !important;
  border-color: #AFAFAF !important ;
}
.google-location-div .css-tlfecz-indicatorContainer,
.google-location-div .css-1gru0rj-indicatorContainer {
  display: none !important;
}
.google-location-div .css-1okebmr-indicatorSeparator {
  display: none !important;
}
.google-location-div .css-1wy0on6 {
  display: none !important;
}
.google-location-div .css-1pahdxg-control {
  border: none !important;
  padding-left: 36px;
  font-size: 14px !important;
}
.google-location-div .css-2b097c-container {
  border: none !important;
}
