.cover-admin-dropdown {
  font-size: 14px;
  text-align: right;
  margin-right: 14px;
  color: #000000;
}
.dropdown-admin {
  font-size: 12px;
  color: #a6acbe;
}
