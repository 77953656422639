.recover-form-cover {
  padding-top: 71px;
  padding-left: 48px !important;
  padding-right: 23px !important;
}
.recover-title {
  font-size: 38px;
  color: #565656;
  font-weight: bold;
  margin-bottom: 85px;
}
.recover-login-span {
  color: #2BC155;
  font-weight: 700;
}
.recover-login-span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.recover-mobile-cover {
  display: none;
}

.desktop-view-forget-password {
  display: block;
}
@media (max-width: 768px) {
  .desktop-view-forget-password {
    display: none;
  }
  .recover-mobile-cover {
    display: block;
  }
  .recover-mobile-title {
    padding-top: 108px;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    color: #1c4178;
  }
  .recover-mobile-input-form {
    height: 48px !important;
    border: 1px solid #0f0d23 !important;
    border-radius: 4px !important;
    padding-left: 16px !important;
    font-size: 14px !important;
    color: #333333 !important;
  }
}
