.mobile-card-cover-div {
  display: none;
}
@media (max-width: 768px) {
  .mobile-card-cover-div {
    background-color: white;
    padding-left: 16px;
    padding-right: 26px;
    border-radius: 30px;
    box-shadow: 0px 18px 17px rgba(0, 0, 0, 0.05);
    display: block;
  }
}
