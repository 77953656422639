.sidebar-cover {
  background-color: white;
  height: 100vh;
  width: 100%;
  background-image: url("../../images/Pattern.png");
  background-repeat: no-repeat;
  border-right: 1px solid #c4bfbf;
  background-position: bottom;
  background-size: contain;
  object-position: bottom;
  overflow-y: auto;
}
.logo-band {
  padding: 13px;
  padding-top: 30px;
  padding-left: 31px;
}
.list-cover {
  margin-top: 64px;
}
@media (max-width: 768px) {
  .logo-band {
    display: none;
  }
  .list-cover {
    margin-top: 54px;
  }
}
