.dashboard-card-main-cover {
  box-shadow: 0px 18px 17px rgba(0, 0, 0, 0.05);
  width: 100%;
  background-color: white;
  /* height: 280px; */
  border-radius: 30px;
  padding-right: 40px !important;
  padding-left: 42px !important;
  display: block;
}
.card-wallet-logo-div {
  padding-top: 66px;
}
.dashboard-balance-card {
  padding-top: 50px;
}
.balance-text {
  font-size: 20px;
  color: black;
  font-weight: 500;
  margin-bottom: 4px !important;
}
.amount-span {
  font-weight: 600;
  font-size: 36px;
  color: black;
}
.eligibility-div {
  color: rgba(0, 0, 0, 0.32);
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  padding-top: 99px;
}

.apply-button {
  width: 201px;
  height: 70px;
  border-radius: 30px !important;
  border: 1px solid#2BC155 !important;
  color: #2BC155 !important;
  font-size: 18px;
  font-weight: 500;
}
.button-div {
  text-align: right;
  padding-top: 60px;
}
.next-payment-div {
  padding-top: 79px;
  display: flex;
  justify-content: flex-end;
}
.dashboard-apply-now-button{
  display: block;
}

.dashboardTitle{
  display: none;
}

@media (max-width: 768px) {
  .dashboard-apply-now-button{
    display: none;
  }
  .dashboard-card-main-cover {
    display: none;
  }

  .dashboardTitle{
    display: flex;
    font-size: 20px !important;
    font-weight: 400 !important;
    margin-bottom: -20px;
    margin-top: 10px;
  }

  .dashboardHome{
    display: none;
  }
}
