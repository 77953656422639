.loan-card-container-active {
  border: 2px solid #1eb6e7;
  height: 166px;
  border-radius: 30px;
  box-sizing: border-box;
  width: 208px;
  cursor: pointer;
}
.loan-card-container-inactive {
  border: 2px solid black;
  height: 166px;
  border-radius: 30px;
  box-sizing: border-box;
  width: 208px;
}
.loan-card-container-inactive:hover {
  cursor: pointer;
  border-color: #1eb6e7;
}

.loan-card-title {
  padding-top: 51px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.loan-card-sub-title {
  padding-top: 6px;
  text-align: center;
  color: #c4c4c4;
  font-size: 14px;
}
.second-card-col-container {
  margin-left: 59px;
}
.third-card-col-container {
  margin-left: 59px;
}
.desktop-select-card-list {
  margin-bottom: 20px;
  display: block;
  margin-right: 50px;
}
.mobile-select-card-list {
  display: none;
}
@media (max-width: 768px) {
  .mobile-select-card-list {
    display: block;
  }
  .desktop-select-card-list {
    display: none;
  }
  .loan-card-mobile-container-active {
    border: 2px solid #1eb6e7;
    border-radius: 30px;
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 7px;
  }
  .loan-card-mobile-container-inactive {
    border: 2px solid black;

    border-radius: 30px;
    box-sizing: border-box;
    margin-bottom: 7px;
  }
  .first-mobile-card {
    margin-left: 25px !important;
    margin-right: 9.5px;
  }
  .second-mobile-card {
    margin-right: 25px !important;
    margin-left: 9.5px;
  }
  .last-mobile-card {
    margin-left: 25px !important;
    margin-right: 9.5px;
  }
  .loan-card-mobile-title {
    padding-top: 31px;
    margin-bottom: 6px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: black;
  }
  .loan-card-mobile-subtitle {
    padding-bottom: 26px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #c4c4c4;
  }
}
