.form-bottom-title-div {
  color: #425a70;
  font-size: 14px;
  padding-top: 18px;
  padding-left: 52px;
}

@media (max-width: 768px) {
  .mobile-form-normal-input {
    width: 100% !important;
  }
}
