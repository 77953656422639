.icon-list {
  cursor: pointer;
}

/* .MuiMenuItem-root {
  height: 55px !important;
} */
.dropdown-toggle:focus {
  box-shadow: none !important;
  border: none !important;
}
