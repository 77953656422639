.profile-edit-cover {
  height: calc(100vh - 74px);
  background-color: #F9F9F9;
  overflow-y: auto;
}
.edit-profile-cover {
  padding-left: 43px;
  margin-top: 63px;
}

@media (max-width: 768px) {
  .edit-profile-cover {
    padding-left: 20px;
    margin-top: 63px;
    padding-right: 20px;
  }
  .avater-cover-div {
    display: flex;
    justify-content: center;
    padding-left: 0px !important;
  }
}
