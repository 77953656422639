@font-face {
  font-family: Montserrat;
  src: url("../../fonts/Montserrat/Montserrat-Regular.ttf");
}
.header-cover {
  display: block;
}
.content-cover {
  margin-bottom: 100px;
}
.content-root-cover {
  padding-top: 96px;
  height: calc(100vh - 71px);
  background-color: inherit;
  overflow-y: auto;
}
.landing-image-content {
  position: absolute;

  z-index: 2000;
  width: 100%;
  height: 100%;
  padding-top: 348px;
}
.landing-image {
  z-index: -1;

  /* height: calc(100vh - 250px); */
}
.enjoy-cover {
  padding: 0px !important;
  position: relative;
}

.landing-row-cover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 200px;
}

.title {
  color: white;
  font-size: 52px;
  letter-spacing: 0.47 px;
  font-weight: 700;
  padding-left: 32px;
  font-family: Montserrat !important;
  margin-bottom: 12px;
}
.sub-title {
  color: white;
  font-size: 18px;
  letter-spacing: 0.47 px;
  font-weight: 700;
  padding-left: 32px;
  font-family: Montserrat !important;
}
.shop-anywhere {
  padding-top: 159px;
  width: 100%;
}
.shop-anywhwere-title {
  color: #425a70;
  line-height: 40.2px;
  letter-spacing: 1%;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 18px;
  line-height: 40.2px;
}

.shop-anywhwere-subtitle {
  letter-spacing: 0.01em;
  color: #425a70;
  font-size: 14px;
  text-align: center;
  line-height: 22.4px;
  margin-bottom: 25px;
}
.get-start-button-div {
  display: flex;
  justify-content: center;
  margin-bottom: 9px;
}
.get-start-button {
  width: 70%;
  background-color: #2763a2 !important;
  color: white !important;
  border-radius: 15px !important;
  height: 48px !important;
}
.login-button {
  width: 70%;
  background-color: #1ce6da !important;
  color: white !important;
  border-radius: 15px !important;
  height: 48px !important;
}
.mobile-view-layout {
  display: none;
}
@media (max-width: 768px) {
  .header-cover {
    display: none;
  }
  .landing-image {
    width: 100%;
    height: 100%;
  }
  .content-root-cover {
    padding-top: 68px;
    height: 100vh;
    overflow-y: auto;
    background: linear-gradient(
      180deg,
      rgba(11, 13, 38, 0) 0%,
      rgba(11, 13, 38, 0.7) 90.99%
    );
  }
  .content-cover {
    display: none;
  }
  .landing-row-cover {
    box-shadow: none;
    margin-bottom: 200px;
  }
  .mobile-view-layout {
    display: block;
  }
  .landing-page-mobile-logo {
    text-align: center;

    margin-bottom: 67px;
  }
  .mobile-partten {
    z-index: 1000;
    padding-top: 44px;
    position: absolute;
  }
  .landing-page-mobile-content-cover {
    position: relative;
    width: 100%;
  }
  .mobile-logo-parttern {
    width: 100%;
  }
  .landing-image-mobile {
    width: 182px;
    height: 162px;
    border-radius: 7px;
  }
  .banner-image-mobile {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 68px;
  }
  .shop-anywhwere-title {
    color: white !important;
    margin-bottom: 36px;
  }
  .shop-anywhwere-subtitle {
    color: white;
    margin-bottom: 28px;
  }
  .get-start-button-div {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 0px 24px 0px 24px;
  }
  .get-start-button {
    width: 100%;
    background-color: #2763a2 !important;
    color: white !important;
    border-radius: 15px !important;
    height: 48px !important;
  }
  .login-button {
    width: 100%;
    background-color: #1ce6da !important;
    color: white !important;
    border-radius: 15px !important;
    height: 48px !important;
    margin-bottom: 33px;
  }
  .landing-mobile-label {
    position: absolute;
    top: 223px;
    width: 100%;
  }
}
