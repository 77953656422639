.repayment-main-cover {
  height: calc(100vh - 74px);
   background-color: #f9f9f9;
  overflow-y: auto;
  padding-left: 20px;
}

.upcoming-repayment-header{
  background-color: white;
  border-radius: 20px;
}

.all-repayment-for-desktop {
  display: block;
}
.repayment-for-mobile {
  display: none;
}
@media (max-width: 768px) {
  .repayment-for-mobile {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }
  .all-repayment-for-desktop {
    display: none;
  }
}
