.mobile-loan-details-cover {

  margin-top: 9px;
  padding-left: 0px;
  padding-top: 20px;
  padding-bottom: 37px;
  border-radius: 11px;
  width: 100%;
  background-color: #F9F9F9;
}
.loan-id-div {
  color: #4662a6;
  font-size: 12px;
  margin-bottom: 2px;
}
.loan-name-div {
  color: #858585;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 3px;
}
.loan-code-div {
  font-size: 12px;
  font-weight: 400;
  color: #858585;
}
.amount-div {
  font-size: 12px;
  font-weight: 700;
  color: #4662a6;
  text-align: right;
  padding-right: 39px !important;
  padding-top: 20px !important;
}
.repayment-details-div {
  color: #4662a6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 21px;
}
.repayment-details-span {
  font-size: 12px;
  color: #858585;
  margin-top: 2px;
}
.loan-details-payment-and-title-div {
  margin-top: 16px !important;
}
.next-payment-details-div {
  font-size: 12px;
  color: #4662a6;
  margin-bottom: 2px;
}
.next-payment-details-div-span {
  font-size: 12px;
  color: #858585;
  font-weight: 400;
}
.next-payment-button-div {
  text-align: right;
  padding-right: 39px !important;
}
