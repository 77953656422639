.my-account-cover {
  padding-left: 73px;
  height: calc(100vh - 74px);
  background-color: #F9F9F9;
  overflow-y: auto;
}
.desktop-edit-profile-button {
  visibility: visible;
}
@media (max-width: 768px) {
  .my-account-cover {
    padding-left: 20px;
    padding-right: 20px;
  }
  .fullname-cover {
    padding-left: 15px !important;
  }
  .desktop-edit-profile-button {
    visibility: hidden;
  }
}
