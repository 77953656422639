.select-form-control {
  border: none !important;
}
.MuiFormControl-root {
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px;
  height: 85px;
  padding-top: 17px !important;
  padding-left: 56px !important;
  padding-right: 36px !important;
}
.MuiFilledInput-root {
  background: transparent !important;
}
.MuiFormLabel-colorPrimary {
  color: #00000099 !important;
}

.form-title-div {
  color: #020614;
  font-size: 12px;
  font-weight: 400;

  padding-top: 37px;
  margin-bottom: 12px;
}
.MuiMenu-list {
  background-color: #f3f6f9 !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  color: #425a70 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #e7f6ff !important;
}
@media (max-width: 768px) {
  .selectInput-for-mobile {
    width: 100% !important;
  }
  .form-title-div {
    /* display: none !important; */
    padding-top: 10px;
  }
}
