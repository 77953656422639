.small-image-banner-signup {
  border-radius: 7px;
}
.small-banner-signup-cover {
  text-align: center;
}
.signup-pattern {
  position: absolute;
  top: -30px;
  z-index: -1;
  right: 0%;
}
