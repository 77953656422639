.css-1wnsr1i {
  border: none !important;
}
.continue-button {
  width: 261px;
  color: white;
  background: #2BC155;
  border: none;
  height: 54px;
  border-radius: 26px;
}
