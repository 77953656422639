.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f6f6f6;
}

.homeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  padding-inline: 50px;
  box-shadow: 1px 2px 10px 1px #f3f3f3;
  background-color: white;
}

.homeHeaderRight {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.homeLogo {
  height: 40px;
  width: fit-content;
  object-fit: contain;
  cursor: pointer;
}

.homeHeaderLogin {
  font-weight: 700;
  margin-right: 100px;
  cursor: pointer;
}

.homeHeaderGetStarted {
  background-color: #47b638;
  color: white;
  padding: 15px 40px;
  border-radius: 15px;
  cursor: pointer;
}

.homeContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.homeContentLeft {
  margin-right: 50px;
}

.homeImage {
  height: 60vh;
  width: fit-content;
  object-fit: contain;
}

.homeContentRight {
  display: flex;
  flex-direction: column;
  height: 65vh;
  flex: 0.5;
  padding-left: 50px;
}

.homeFormHeader {
  font-family: "PT Serif";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
}

.homeFormHeaderDescription {
  font-family: "PT Serif";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 55px;
  margin-bottom: 30px;
}

.homeMobile {
  display: none;
}

@media (max-width: 768px) {
  .homeHeader {
    display: none;
  }

  .homeContent {
    display: none;
  }

  .homeMobile {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding-inline: 50px;
  }

  .homeMobileLogoContainer {
    margin-bottom: 30px;
  }

  .signin-mobile-title {
    font-size: 24px;
    color: #2bc155;
    font-weight: 700;
    margin-top: 47px;
    text-align: center;
  }
  .signin-mobile-sub-title {
    color: #7a869a;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .mobile-signin-submit-button {
    background: #2bc155 !important;
    color: white !important;
    width: 100%;
    height: 64px !important;
    border-radius: 5px !important;
    mix-blend-mode: normal !important;
  }
  .sign-forgot-password-div {
    margin-top: 3px;
    margin-bottom: 27px;
    text-align: left;
    color: #2bc155;
    font-size: 13px;
    font-weight: 500;
  }
  .sign-dont-have-an-account-div {
    font-size: 14px;
    color: #7a869a;
    text-align: center;
    margin-top: 53px;
  }
  .dont-have-account-span {
    color: #2bc155;
    font-weight: 700;
  }
  .dont-have-account-span:hover {
    text-decoration: underline;
  }

  .auth-main-input {
    height: 55px !important;
    border-radius: 3px !important;
    border: 1px solid #ffffff !important;
    background-color: #f0f0f0 !important;
    box-sizing: border-box !important;
    padding-left: 30px !important;
    font-size: 14px !important;
  }

  .homeMobileWelcome{
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: Poppins;
  }

  .homeMobileWelcomeDescription{
    font-size: 18px;
    margin-bottom: 24px;
  }

}
