.next-repayment-main-cover {
  height: calc(100vh - 74px);
  background-color: #F9F9F9;
  overflow-y: auto;
}

.next-repayment-cover-for-mobile {
  display: block;
}
.next-repayment-for-mobile {
  display: none;
}
@media (max-width: 768px) {
  .next-repayment-cover-for-mobile {
    display: none;
  }
  .next-repayment-for-mobile {
    display: block;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
