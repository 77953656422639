.guarantor-cover {
  height: calc(100vh - 74px);
  background-color: #F9F9F9;
  overflow-y: auto;
}
.guarantor-title-cover {
  padding-left: 57px !important;
}
.guarantor-form-cover {
  padding-left: 50px;

  padding-top: 40px !important;
  padding-right: 124px !important;
}
@media (max-width: 768px) {
  .guarantor-title-cover {
    padding-left: 32px !important;
  }
  .guarantor-form-cover {
    padding-left: 32px;
    padding-right: 32px !important;
  }
}
