.personal-details-title {
  padding-left: 57px;
}
.personal-cover {
  height: calc(100vh - 74px);
background-color: #F9F9F9;
  overflow-y: auto;
}
.personal-form-cover {
  padding-left: 81px !important;
  padding-top: 45px !important;
  padding-right: 93px !important;
  padding-bottom: 200px;
}
.desktop-button-div {
  display: block;
}
.mobile-button-div {
  display: none;
}
.first-col {
  padding-left: 0px !important;
}
.second-col {
  padding-right: 0px !important;
}
.inner-first-col {
  padding-left: 0px !important;
}
.inner-second-col {
  padding-right: 0px !important;
}
.mobile-first-col {
  padding-left: 0px !important;
}
.mobile-second-col {
  padding-right: 0px !important;
}
@media (max-width: 768px) {
  .mobile-first-col {
    padding-left: 0px !important;
    padding-right: 6px !important;
  }
  .mobile-second-col {
    padding-left: 6px !important;
    padding-right: 0px !important;
  }
  .inner-first-col {
    padding-left: 0px !important;
    padding-right: 10px !important;
  }
  .inner-second-col {
    padding-right: 0px !important;
    padding-left: 10px !important;
  }
  .first-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .second-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .desktop-button-div {
    display: none;
  }
  .personal-details-title {
    padding-left: 32px;
  }
  .personal-form-cover {
    padding-left: 32px !important;
    padding-top: 45px !important;
    padding-right: 32px !important;
    padding-bottom: 200px;
  }
  .mobile-button-div {
    display: block;
  }
}
