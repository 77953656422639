.loan-list-header {
    width: 100%;
    padding-top: 39px;
    padding-bottom: 28px;
    background-color: #eaffe7;
    border-radius: 11px;
    margin-bottom: 16px;
}

.loan-table-header-title {
    text-align: center;
    color: #858585;
    font-size: 12px;
    font-weight: 400;
}
.loan-table-data-content {
    width: 100%;
    background-color: white;
    position: relative;
}

.loan-data-details-col {
    text-align: center;

    padding-bottom: 36px;
    font-size: 16px;
    font-weight: 700;
    color: #425a70;
}
.loan-data-details-col-active {
    text-align: center;

    padding-bottom: 36px;
    font-size: 16px;
    font-weight: 700;
    color: white;
}
.loan-data-details-col-process {
    padding-top: 20px;
    padding-bottom: 36px;
    font-size: 20px;
    font-weight: 700;
    color: #425a70;
    display: flex;
    justify-content: center;
}
.in-progress-span {
    background-color: #e7f6ff;
    color: #3d18ea;
    font-size: 12px;
    font-weight: 400;
    width: 121px;
    text-align: center;
    border-radius: 26px;
    height: 35px;
    line-height: 35px;
}
.loan-table-data-content {
    margin-bottom: 16px;
    height: 90px;
}
.inner-div-data {
    padding-top: 30px;
}

.mobile-card-cover-page-for-loan {
    padding-left: 15px !important;
    padding-right: 15px !important;
    background-color: #ffffff !important;
    padding-top: 14px;
    padding-bottom: 9px;
}
.content-and-amount-div {
    font-size: 12px;
    font-weight: 400;
    color: #858585;
}
.card-amount-span {
    font-weight: 700;
    font-size: 12px;
    color: #858585;
    margin-top: 3px;
}
.status-card-span {
    background-color: rgba(241, 11, 11, 0.3);
    padding-left: 17px;
    padding-right: 13px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 26px;
    font-size: 10px;
    color: #ff0000;
    font-weight: 400;
}
.loan-main-table-cover {
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
}
.loan-table-data-content-active {
    background-color: #2bc155;
    border-radius: 30px;
}
