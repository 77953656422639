.register-title {
  font-size: 38px;
  font-weight: 700;
  color: #565656;
  letter-spacing: 0.51 px;
  padding-top: 15px;
  margin-bottom: 23px;
}
.register-form-cover {
  margin-bottom: 15px;
  padding-left: 36px !important;
  padding-right: 46px !important;
}
.register-sub-title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.51 px;
  margin-bottom: 20px;
}
.signup-container {
  padding-top: 20px;
  height: calc(100vh - 71px);
  background-color: inherit;
  overflow-y: auto;
  padding-bottom: 50px;
}
.sign-up-desktop {
  display: block !important;
}
.sign-up-mobile {
  display: none !important;
}
.form-component-cover {
  margin-bottom: 15px;
}
.agreement-div {
  font-size: 10px;
  font-weight: 600;
  display: block;
  color: #b2bcca;
}
.desktop-submit-button {
  margin-top: 30px;
}
.desktop-submit-button {
  width: 100%;
  height: 55px;
  background: #47B638 !important;
  color: white !important;
  border-radius: 0px !important;
}
.desktop-submit-button-div {
  display: block !important;
}
.mobile-submit-button-div {
  display: none;
}
.hav-an-account-span:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .mobile-submit-button-div {
    display: block;
  }
  .mobile-submit-button {
    background-color: #47B638 !important;
    color: white !important;
    width: 100%;
    margin-top: 20px;
    height: 44px;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
  }
  .desktop-submit-button-div {
    display: none !important;
  }
  .agreement-div {
    font-size: 10px;
    font-weight: 600;
    display: block;
  }
  .signup-container {
    padding-top: 56px;
    height: calc(100vh - 71px);
    background-color: inherit;
    overflow-y: auto;
  }
  .sign-up-desktop {
    display: none !important;
  }
  .sign-up-mobile {
    display: block !important;
  }
  .register-title {
    font-size: 24px;
    font-weight: 700;
    color: #565656;
    letter-spacing: 0.51 px;
    padding-top: 16px;
    text-align: center;
    margin-bottom: 7px;
  }
  .register-sub-title {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.51 px;
    text-align: center;
    margin-bottom: 15px;
    color: #565656;
  }
  .form-component-cover {
    margin-bottom: 9px;
  }
  .desktop-submit-button {
    margin-top: 20px;
  }
  .register-form-cover {
    margin-bottom: 23px;
    padding-left: 34px !important;
    padding-right: 34px !important;
  }
}
