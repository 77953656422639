.signin-title {
  margin-top: 30px;
  font-size: 38px;
  font-weight: 700;
  color: #565656;
  letter-spacing: #565656;
  margin-bottom: 30px;
}

.signin-form-cover {
  margin-bottom: 20px;
}
.signin-link-span:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 768px) {
  .signin-mobile-title {
    font-size: 24px;
    color: #2bc155;
    font-weight: 700;
    margin-top: 47px;
    text-align: center;
  }
  .signin-mobile-sub-title {
    color: #7a869a;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .mobile-signin-submit-button {
    background: #2bc155 !important;
    color: white !important;
    width: 100%;
    height: 44px !important;
    border-radius: 10px !important;
    mix-blend-mode: normal !important;
  }
  .sign-forgot-password-div {
    margin-top: 3px;
    margin-bottom: 27px;
    text-align: center;
    color: #2bc155;
    font-size: 13px;
    font-weight: 500;
  }
  .sign-dont-have-an-account-div {
    font-size: 14px;
    color: #7a869a;
    text-align: center;
    margin-top: 53px;
  }
  .dont-have-account-span {
    color: #2bc155;
    font-weight: 700;
  }
  .dont-have-account-span:hover {
    text-decoration: underline;
  }
}
