.create-loan-cover {
  height: calc(100vh - 74px);
  background-color: #f9f9f9;
  overflow-y: auto;
  padding-left: 45px;
}

@media (max-width: 768px) {
  .create-loan-cover {
    height: calc(100vh - 74px);
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
