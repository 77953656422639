.select-loan-cover {
  height: calc(100vh - 74px);

  overflow-y: auto;
  background-color: #f9f9f9;
}
.select-loan-title {
  font-size: 20px;
  font-weight: bold;
  color: black;
  padding-left: 57px;
  padding-top: 48px;
  display: block;
}

.select-loan-card-div {
  padding-top: 28px;
  padding-left: 86px;
  display: block;
}

.select-loan-form-cover {
  padding-left: 86px !important;
}
.desktop-apply-button {
  margin-bottom: 296px;
  display: block;
}
.mobile-apply-button {
  display: none;
}
.select-product-option {
  display: none;
}
.select-loan-mobile-card-div {
  display: none;
}
@media (max-width: 768px) {
  .select-loan-mobile-card-div {
    display: block;
    margin-top: 51px;
    margin-bottom: 34px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .select-product-option {
    display: block;
  }
  .mobile-apply-button {
    display: block;
    text-align: center;
    margin-bottom: 169px;
    margin-top: 34px;
  }
  .desktop-apply-button {
    margin-bottom: 296px;
    display: none;
  }
  .select-loan-title {
    /* display: none; */
    padding-left: 15px;
    padding-top: 32px;
    padding-right: 15px;
    font-size: 16px;
  }
  .select-loan-card-div {
    display: none;
  }
  .select-loan-form-cover {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .input-div-cover {
    margin-bottom: 7px !important;
    margin-top: 10px;
  }

  .select-loan-mobile-card-div-text{
    display: none;
  }

}
