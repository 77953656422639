.change-password-cover {
  padding-left: 43px;
  height: calc(100vh - 74px);
  background-color: #F9F9F9;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .change-password-cover {
    padding-left: 20px;
    padding-right: 20px;
    height: calc(100vh - 74px);

    overflow-y: auto;
  }
}
