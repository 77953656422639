.icon-list {
  display: none !important;

}

.dasktop-logo{
  display: block !important;
}
.mobile-logo{
  display: none !important;
}
@media (max-width: 500px) {
  .main-sidebar-cover {
    display: none;
  }
  .dasktop-logo{
    display: none !important;
  }
  .mobile-logo{
    display: block !important;
  }

  .mobileHeaderColor{
    background-color: #47B638 !important;
  }
}
