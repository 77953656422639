.bvn-title {
  margin-top: 48px;
  font-weight: bold;
  font-size: 20px;
  padding-left: 57px;
  margin-bottom: 7px;
}
.bvn-sub-title {
  color: #020614;
  font-size: 12px;
  opacity: 0.5;
  padding-left: 57px;
}
.bvn-form-cover {
  padding-left: 60px;
  padding-top: 43px;
  background-color: #f9f9f9;
}
.bvn-inner-form-cover {
  margin-bottom: 12px;
}
.bvn-identification-number-cover {
  height: calc(100vh - 74px);

  overflow-y: auto;
}
@media (max-width: 768px) {
  .bvn-title {
    margin-top: 48px;
    font-weight: 700;
    font-size: 20px;
    padding-left: 21px;
    margin-bottom: 12px;
  }
  .bvn-sub-title {
    color: #020614;
    font-size: 12px;
    opacity: 0.5;
    padding-left: 21px;
  }
  .bvn-form-cover {
    padding-left: 21px;
    padding-right: 38px;
    padding-top: 28px;
  }
  .mobile-primary-button-cover {
    text-align: center;
    padding-bottom: 66px !important;
  }
  .bvn-inner-form-cover {
    margin-bottom: 6px;
  }
}
