.employment-title {
  margin-left: 57px;
}
.employment-form-cover {
  padding-left: 57px;
  margin-top: 48px;
  background-color: #F9F9F9;
}
.employment-cover {
  height: calc(100vh - 74px);

  overflow-y: auto;
}
.employment-location-cover {
  padding-right: 30px;
}
@media (max-width: 768px) {
  .employment-location-cover {
    padding-right: 0px;
  }
  .employment-title {
    margin-left: 28px;
    margin-right: 34px;
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .employment-form-cover {
    padding-left: 28px;
    margin-top: 37px;
    padding-right: 34px;
  }
  .mobile-first-cover-button {
    text-align: center;
  }
}
