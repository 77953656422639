.table thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid hsl(0, 0%, 87%);
  border-color: #e7e7e7 !important;
  background-color: #EAFFE7;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 6px;
}
.table-header-title {
  font-size: 16px;
  font-weight: bold;
  color:  #425a70 ;
}
.rec-and-amount {
  font-size: 18px;
  color: #425a70;
  font-weight: bold;
}
.rec-data-amount {
  font-size: 18px;
  font-weight: bold;
  color:  #425a70 ;
}
.list-data-record {
  color:  #425a70  !important;
  font-size: 16px;
  font-weight: normal;
}
.start-table-data {
  padding-left: 17px !important;
}
