.existing-loan-cover {
  height: calc(100vh - 74px);
  background-color: #F9F9F9;
  overflow-y: auto;
  padding-left: 70px;
}
.existing-loan-title {
  padding-left: 48px;
  padding-bottom: 44px;
}
.view-loan-list-cover {
  padding-left: 48px;
  padding-right: 83px;
  background-color: white;
  border-radius: 30px;
  padding-block: 20px;
  margin-right: 100px;
}
.desktop-view-for-existing-loan {
  display: block;
  margin-top: 50px;
}
.mobile-view-for-existing-loan {
  display: none;
}
@media (max-width: 768px) {
  .desktop-view-for-existing-loan {
    display: none;
  }
  .mobile-view-for-existing-loan {
    display: block;
    margin-top: 50px;
  }
  .mobile-existing-loan-title {
    padding-left: 28px;
    margin-bottom: 35px;
  }
  .mobile-view-loan-list-cover {
    /* padding-left: 21px;
    padding-right: 35px; */
  }
  .mobile-view-list-existing-loan {
    margin-bottom: 11px;
    margin-left: 21px;
    margin-right: 31px;
  }

  .existing-loan-cover{
    padding-inline: 10px;
  }
}
