.repayment-cover {
  height: calc(100vh - 74px);
  background-color: #F9F9F9;
  overflow-y: auto;
}
.repayment-title-cover {
  padding-top: 56px;
  padding-left: 42px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 36px;
}
.due-payment-cover {
  padding-left: 42px;
  border-radius: 11px;
}
.due-repayment-cols {
  font-size: 12px;
  font-weight: 18;
  color: #858585;
  padding-top: 24px;
  padding-bottom: 16px;
}
.due-repayment-col-span {
  padding-top: 8px !important;
  font-size: 12px;
  color: #464a53;
}
.due-payment-status {
  background-color: rgba(255, 0, 0, 0.09);
  padding-left: 16px;
  padding-right: 19px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #f30f0f;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 400;
}
.repayment-due-button-cover {
  padding-top: 20px;
  text-align: center;
}
.upcoming-repayment-title {
  padding-left: 48px;
  padding-top: 71px;
  padding-bottom: 21px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.upcoming-repayment-header {
  background-color: #E7FFEE;
}
.right-upcoming-repayment-header {
  text-align: right;
  padding-right: 45px !important;
  padding-top: 39px;
  padding-bottom: 28px;
}
.upcoming-header-cover {
  padding-left: 33px !important;
  padding-right: 45px !important;
  color: #858585;
  font-size: 12px;
}
.upcoming-repayment-content {
  padding-left: 33px !important;
  padding-right: 45px !important;
}
.left-upcoming-repayment-header {
  font-size: 12px;
  color: #858585;
  padding-left: 33px !important;
  padding-top: 39px;
  padding-bottom: 28px;
}
.upcoming-repayment-content {
  margin-top: 16px;
}
.repayment-upcoming-button-cover {
  padding-top: 20px !important;
  text-align: left;
  margin-left: -30px !important;
}
.desktop-view-screen {
  display: block;
}
.mobile-repayment-view {
  display: none;
}
@media (max-width: 768px) {
  .mobile-repayment-view {
    display: block;
  }
  .desktop-view-screen {
    display: none;
  }
  .repayment-for-mobile-first-details {
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 47px;
  }
  .mobile-for-repayment-first-card-row {
    padding-left: 24px;
    padding-right: 15px;
  }
  .mobile-for-repayment-first-card-1 {
    font-size: 12px;
    font-weight: 400;
    color: #858585;
  }
  .mobile-for-repayment-first-card-span {
    font-size: 12px;
    font-weight: 700;
    color: #858585;
    margin-top: 3px;
  }
  .mobile-for-repayment-first-card-status {
    color: #ff0000;
    font-size: 10px;
    font-weight: 400;
    padding-right: 19px;
    padding-left: 15px;
    padding-top: 1px;
    padding-bottom: 3px;
    background-color: rgba(241, 11, 11, 0.3);
    border-radius: 8px;
  }
  .mobile-second-repayment-title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    margin-top: 60px;
    margin-bottom: 31px;
    padding-left: 32px;
  }
  .second-mobile-repayment-content-cover {
    padding-left: 33px;
    padding-right: 33px;
  }
}
