.borrow-loan-table tr:nth-child(odd) {
  background-color: #EAFFE7;
  border-color: #e7f6ff;
}
.borrow-loan-table tr:nth-child(even) {
  background-color: white;
  border-color: white;
}
.borrow-loan-table table tr {
  height: 60px;
  line-height: 60px;
}
.borrow-loan-cover {
  height: calc(100vh - 74px);

  overflow-y: auto;
}
.borrow-loan-table-cover {
  padding-left: 38px;
  padding-right: 68px;
  background-color: #F9F9F9;
}
.borrow-title-cover {
  padding-left: 38px;
  padding-bottom: 54px;
}
.loan-schedule-for-mobile {
  display: none;
}
.borrow-loan-for-desktop {
  display: block;
}
@media (max-width: 768px) {
  .borrow-loan-for-desktop {
    display: none;
  }
  .loan-schedule-for-mobile {
    display: block;
    padding-left: 21px;
    padding-right: 33px;
  }
}
