.credit-check-title {
  padding-left: 57px;
}
.credit-check-cover {
  height: calc(100vh - 74px);

  overflow-y: auto;
  padding-bottom: 500px;
  background-color: #F9F9F9;
}
.credit-check-form-cover {
  padding-top: 47px;
  padding-left: 57px;
}
.credit-check-network-checker {
  padding-top: 46px;
}
.check-button-div {
  margin-top: 37px;
}
.bank-statement-cover {
  margin-top: 36px;
  padding-left: 65px;
}
.upload-bank-statement-div {
  padding-top: 30px;
}
@media (max-width: 768px) {
  .credit-check-title {
    padding-left: 32px;
  }
  .credit-check-form-cover {
    padding-top: 47px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .bank-statement-cover {
    margin-top: 39px;
    padding-left: 28px;
  }
}
