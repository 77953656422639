@font-face {
  font-family: Poppins;
  src: url("./fonts/poppins/Poppins-Regular.ttf");
}
.MuiAppBar-colorPrimary {
  background-color: #F9F9F9 !important;
}
.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
  padding-right: 50px !important;
}

* {
  font-family: Poppins !important;
}

.main-sidebar-cover {
  display: block;
}
.mobile-sidebar-cover {
  display: none;
}
.enjoy-cover {
}
body {
  background-color: #F9F9F9;
}
@media (max-width: 768px) {
  .main-sidebar-cover {
    display: none;
  }
  .mobile-sidebar-cover {
    position: absolute;
    top: 87px;
    height: 89vh;
    z-index: 1000;
    overflow-y: auto;
    width: 70% !important;
    transition: 0.3s all;
  }
  .css-hip9hq-MuiPaper-root-MuiAppBar-root {
    box-shadow: none !important;
    padding-right: 0px !important;
  }
  .MuiAppBar-colorPrimary {
    background: #2BC155 !important;
  }
}
/* width */
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F9F9F9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F9F9F9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#F9F9F9;
}