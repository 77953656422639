.auth-input-cover {
  position: relative;
}

.validation-icon {
  position: absolute;
  left: 85%;
  top: 30%;
}

.auth-main-input {
  height: 67px !important;
  /* border-radius: 43px !important; */
  border: 1px solid #AFAFAF !important;
  background: #f0f0f0 !important;
  box-sizing: border-box !important;
  padding-left: 25px !important;
  font-size: 14px !important;
}

@media (max-width: 768px) {
  .validation-icon {
    position: absolute;
    left: 90%;
    top: 30%;
  }
  .auth-main-input {
    height: 55px !important;
    border-radius: 43px !important;
    border: 1px solid #ffffff !important;
    background-color: #f0f0f0 !important;
    box-sizing: border-box !important;
    padding-left: 30px !important;
    font-size: 14px !important;
  }
}
