.dashboard-cover {
  height: calc(100vh - 74px);
background-color: #F9F9F9;
  overflow-y: auto;
}
.dashboard-card-cover {
  padding-top: 61px !important;
  padding-left: 76px !important;
  padding-right: 30px !important;
}
.dashboard-content-title {
  padding-top: 117px;
  padding-left: 66px;
  font-size: 16px;
  color: black;
  font-weight: 400;
}
@media (max-width: 768px) {
  .dashboard-card-cover {
    padding-top: 17px !important;
    padding-left: 21px !important;
    padding-right: 26px !important;
  }
  .dashboard-content-title {
    padding-top: 58px;
    padding-left: 52px;
  }
}
